import { DatePage } from '@did/das-app-modules'
import { toLowerCase } from '@did/tools'
import { useRouter } from 'next/router'
import React from 'react'

const Index: React.FC<any> = () => {
  const { query } = useRouter()

  return <DatePage bitName={toLowerCase(query?.bitname as string)} />
}

export default Index
